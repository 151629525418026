import React from "react";
import "./Bio.css";
import picture from "../fotos/miguel-10.jpg"

function Bio() {
  return (
    <div className="bio-section">
      <div className="bio-container">
        <div className="text-container">
          <h1>MIGUEL DIAS</h1>
          <p>
            Miguel Dias is an alternative RnB artist based in Portugal. As a
            well-seasoned traveller, his expeditions abroad sewed the seeds for
            his eclectic taste in music, and have cultivated a broad and
            colourful palette of tones that mark the core of his sound. It’s a
            blend of styles, influenced and inspired by everything from
            classical to hip hop, jazz, bossa nova and even pop. Above all, his
            music serves as a personal journal; an outlet for which he uses to
            open the pathways to discussion, surrounding topics of the modern
            day condition -- everything from love to politics and more. Miguel,
            while a young up-and-comer, is no stranger to music. Having studied
            classical guitar from a young age, he has since developed a strong
            ear and sense for what works and what doesn’t. Simply put -- music
            is nothing but passion, and the only thing that makes him feel truly
            alive. Miguel creates at home in his own personal studio, and often
            collaborates with professional session musicians to formulate his
            ideas. His debut release titled ‘Break Down’ dropped September 3rd
            2019, and marks the first step in his journey as a professional
            artist. The single is currently out on all major streaming
            platforms.
          </p>
        </div>
        <div className="picture-container">
          <img alt="" src={picture}></img>
        </div>
      </div>
    </div>
  );
}

export default Bio;
