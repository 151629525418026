import React from "react";
import "./SongCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpotify, faAmazon, faGooglePlay, faApple, faYoutube } from "@fortawesome/free-brands-svg-icons"

function SongCard(props) {
  return (
    <>
      <div className="song-card">
        <img alt="" id="card-pic" src={props.pictureSource}></img>
        <a href={props.spotifyRef} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faSpotify} size="3x" className="icon"/>
        </a>
        <a href={props.amazonRef} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faAmazon} size="3x" className="icon"/>
        </a>
        <a href={props.appleRef} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faApple} size="3x" className="icon"/>
        </a>
        <a href={props.googlePlayRef} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGooglePlay} size="3x" className="icon"/>
        </a>
        <a href={props.youtubeRef} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faYoutube} size="3x" className="icon"/>
        </a>
      </div>
    </>
  );
}

export default SongCard;
