import React from "react";
import "./Menu.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Tour from "../Tour/Tour";
import Videos from "../Videos/Videos";
import Music from "../Music/Music";
import Photos from "../Photos/Photos";
import Home from "../Home/Home";
import Bio from "../Bio/Bio";
import $ from "jquery";
import logo from "../fotos/logo.png";

function Menu() {
  function handleClick() {
    var newWindowWidth = $(window).width();
    if (newWindowWidth < 900) {
      $("#menucontainer").toggle();
    }
  }
  return (
    <Router>
      <>
        <label for="toggle" onClick={handleClick}>
          &#9776;
        </label>
        <div
          className="menu-container"
          id="menucontainer"
        >
          <div className="logo-container">
            <Link to="/" onClick={handleClick}>
              <img alt="" src={logo} id="logo"></img>
            </Link>
          </div>
          <div className="menu-options-container rectangle">
            <Link to="/" onClick={handleClick} className="home">
              HOME
            </Link>
            <Link to="/bio" onClick={handleClick}>
              BIO
            </Link>
            <Link for="menu-option" to="/tour" onClick={handleClick}>
              TOUR
            </Link>
            <Link to="/music" onClick={handleClick}>
              MUSIC
            </Link>
            <Link to="/videos" onClick={handleClick}>
              VIDEOS
            </Link>
            <Link to="/photos" onClick={handleClick}>
              PHOTOS
            </Link>
          </div>
        </div>
      </>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/tour">
          <Tour />
        </Route>
        <Route path="/music">
          <Music />
        </Route>
        <Route path="/videos">
          <Videos />
        </Route>
        <Route path="/photos">
          <Photos />
        </Route>
        <Route path="/bio">
          <Bio />
        </Route>
      </Switch>
    </Router>
  );
}

export default Menu;
