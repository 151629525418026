import React from "react";
import "./Tour.css";


function Tour() {
  return (
    <>
    <div className="tour">
    <h4>Sorry, there are no shows currently available. </h4>
    </div>
    </>
  );
}

export default Tour;
