import React, {useState} from "react";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faYoutube, faSpotify } from "@fortawesome/free-brands-svg-icons"
import logo from "../fotos/logo.png";
import { Link, Router } from "react-router-dom";
import $ from "jquery";

function Header() {
  const [isWideScreen, setIsWideScreen] = useState($(window).width() > 1600);

  return (
      <div>
        <div className="logo-container">
              <img alt="" src={logo} id="logo"></img>
          </div>
      <div className="social-media-container">
       <a href="https://open.spotify.com/artist/6uABP2PlyGOiUPKl8T3CoY" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faSpotify} size={isWideScreen ? "4x" : "2x"} style={{ color: 'rgb(67, 170, 76)' }}/></a>
      <a href="https://www.facebook.com/miguel.dias.961993" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} size={isWideScreen ? "4x" : "2x"} style={{ color: '#4267B2' }}/></a>
      <a href="https://www.instagram.com/migueldias.music/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} size={isWideScreen ? "4x" : "2x"} style={{ background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)', color: 'white', borderRadius:'10px', padding:'0.1rem' }}/></a>
      <a href="https://www.youtube.com/channel/UCNOTEiwmQMHL0tD2cR39lbg" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} size={isWideScreen ? "4x" : "2x"} style={{ color: 'red' }}/></a>
      </div>
    </div>
    
  );
}

export default Header;
