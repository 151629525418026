import React from "react";
import "./Home.css";


function Home() {
  return (
    <div id="home">
    </div>
  );
}

export default Home;
