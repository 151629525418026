import React from "react";
import "./Footer.css";
import logo from "../fotos/logo.png"

function Footer() {
  return (
    <div className="footer-container">
      <div className="logo-div">
      <img alt="" src={logo}></img>
      </div>
      <div className="all-rights-reserved">
        <p>© 2020 MIGUEL DIAS</p>
        <p>BUILT BY <a id="bits" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/beatrizalmeidapires/">BITS.</a></p>
      </div>
    </div>
  );
}

export default Footer;
