import React from "react";
import "./App.css";
import Header from "./Header/Header";
import Menu from "./Menu/Menu";
import Footer from "./Footer/Footer";

function App() {
  return (
    <>
    <Header/>
    <Menu/>
    <Footer/>
   </>
  );
}

export default App;
