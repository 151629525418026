import React from "react";
import "./Videos.css";
import Carousel from "react-bootstrap/Carousel";

function Videos() {
  return (
    <div className="videos-section">
    <div className="videos-container">
      <Carousel>
        <Carousel.Item>
          <iframe
            width="700"
            height="400"
            src="https://www.youtube.com/embed/qcG2Xegh13g"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="music-video"
          ></iframe>
        </Carousel.Item>
        <Carousel.Item>
          <iframe
            width="700"
            height="400"
            src="https://www.youtube.com/embed/h-q5mcMLyVw"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="music-video"
          ></iframe>
        </Carousel.Item>
      </Carousel>
    </div>
    </div>
  );
}

export default Videos;
