import React from "react";
import "./Music.css";
import SongCard from "./SongCards/SongCard";

function Music() {
  return (
    <>
      <div id="music">
        <div className="cards-container">
          <div className="card1">
            <SongCard
              pictureSource="https://m.media-amazon.com/images/I/81h+rqqpWDL._SS500_.jpg"
              spotifyRef="https://open.spotify.com/track/2RJsc3MxI6vPPnzHM0eGBo"
              amazonRef="https://www.amazon.com/Made-Sell-Explicit-Miguel-Dias/dp/B082MTJYXV"
              appleRef="https://music.apple.com/us/album/made-to-sell/1491144243?i=1491144493"
              googlePlayRef="www.google.com"
              youtubeRef="https://www.youtube.com/watch?v=qcG2Xegh13g"
            />
          </div>
          <div className="card2">
            <SongCard
              pictureSource="https://m.media-amazon.com/images/I/81zqC+4V0LL._SS500_.jpg"
              spotifyRef="https://open.spotify.com/track/1rjhUPh3zxzPBtNLA6cqak"
              amazonRef="https://www.amazon.com/Break-Down-Miguel-Dias/dp/B07X7N6T2C"
              appleRef="https://music.apple.com/us/album/break-down/1478413173?i=1478413187"
              googlePlayRef="www.google.com"
              youtubeRef="https://www.youtube.com/watch?v=h-q5mcMLyVw"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Music;
