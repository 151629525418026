import React from "react";
import "./Photos.css";
import image1 from "../fotos/miguel-1.jpg"
import image2 from "../fotos/miguel-2.jpg"
import image3 from "../fotos/miguel-3.jpg"
import image4 from "../fotos/miguel-4.jpg"
import image5 from "../fotos/miguel-5.jpg"
import image6 from "../fotos/miguel-6.jpg"
import image7 from "../fotos/miguel-7.jpeg"
import image12 from "../fotos/miguel-12.jpg"



function Photos() {
  return (
    <div className="photos-container">
    <img alt="" src={image1}></img> 
    <img alt="" src={image2}></img>
    <img alt="" src={image3}></img> 
    <img alt="" src={image4}></img> 
    <img alt="" src={image5}></img> 
    <img alt="" src={image6}></img> 
    <img alt="" src={image12}></img> 
    <img alt="" src={image7}></img> 
    </div>
  );
}

export default Photos;
